import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavbarBrand } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import local from '../../services/local';
import api from '../../services/api';
import i18n from '../../i18n/i18n';
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};


class DefaultHeader extends Component {
  async onLogoutClick() {
    await api.logout();
    local.clear();
    window.location.href = '';
  }

  async changeLanguage(lng) {
    alert(lng)
    i18n.changeLanguage(lng);
  }
  render() {
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment className="header-site">
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <NavbarBrand className='appnavbarName'><Link to='/dashboard'>AMBULANCE</Link></NavbarBrand>
        <AppSidebarToggler className="d-md-down-none appnavbarName" display="lg" />
        <Nav className="ml-auto" navbar><AppHeaderDropdown direction="down">

          <DropdownToggle nav>
            <img className="img-avatar" src="https://www.kindpng.com/picc/m/130-1300217_user-icon-member-icon-png-transparent-png.png" alt="Card image cap" />
          </DropdownToggle>

          <DropdownMenu right >
            <DropdownItem tag={Link} to='/profile'>
              <i className="fa fa-user"></i> Profile
              </DropdownItem>
            <DropdownItem onClick={this.onLogoutClick.bind(this)}><i className="fa fa-lock"></i>Log Out</DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

// export default DefaultHeader;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}
export default connect(mapStateToProps)(DefaultHeader);
// export default withNamespaces();