import request from './request';

var config = {}



var path = {
    //login
    login: '/api/user/login',
    logout: '/api/user/logout',
    getCaptcha: '/api/user/create-captcha',
    getMeta: '/api/admin/get-meta',
    refreshToken: '/user/refreshToken',

    getLanguage: '/language/getList',
    getAllConfig: '/config/getAllConfig',

    createRole: '/role/create',
    updateRole: '/role/update',
    getListRole: '/role/getList',
    findPermissionPage: '/permission/findPerPage',
    savePermission: '/permission/save',
    checkPermissionButton: '/permission/checkPerButton',
    getListPermission: '/permission/getList',

    getDetailHistoryPerson: '/personHistory/getDetailPerson',
    treatmentHistory: '/personHistory/treatmentHistory',
    getDetailPerson: '/person/getDetail',
    getListByDate: "/personHistory/getListByDate",
    getUser: "/dashborad/getUser",
    getInfo: '/user/getInfo',
    changePassword: '/user/changePass',
    updateProfile: '/user/updateProfile',

    getRecordLatest: '/personHistory/getRecordLatest',
    getDetailCarHistory: '/claimhistory/getDetailCarHistory'

};


Object.keys(path).forEach(function (key) {

    config[key] = async function (data) {
        let result = await request.request(path[key], data)
        return result
    }
}, this);




export default config;