import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux'
import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultHeader from './DefaultHeader';
import local from '../../services/local';
import helper from '../../services/helper';
import api from '../../services/api';
import _ from 'lodash';
import i18next from 'i18next';
class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      nav: []
    }
  }

  async componentDidMount() {
    try {
      var session = await local.get('session')
      if (!session) {
        this.props.history.replace('/login');
      } else {
        let meta = await api.getMeta();
        let config = await api.getAllConfig();
        helper.configs = config.data // cache list config
        meta.pages.map(page => {
          for (var i in page.schema) {
            page.schema[i].pageId = page.sid;
          }
          return null;
        })
        local.set('meta', JSON.stringify(meta));
        let userInfo = local.get('user');
        let nav = this.calculateNav(meta.menus, userInfo.role);
        this.setState({ loading: false, nav });
      }
      window.addEventListener('beforeunload', this.handleLeavePage);
    } catch (err) {
      console.log('loi roi , err here', err)
      this.props.history.replace('/login');
    }
  }
  /**
   * @author TuPT
   * @createdAt 06/06/2020
   * @description confirm when reload or leave page
   */
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage);
  }

  handleLeavePage(e) {
    const confirmationMessage = 'Some message';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }
  calculateNav(allMenus, role) {
    let menus = allMenus;
    // allMenus.map(m => {
    //   m.isOpen = false;
    //   if (!m.roles) {
    //     return menus.push(m);
    //   }
    //   if (m.roles.length === 0) {
    //     return menus.push(m);
    //   }
    //   if (_.includes(m.roles, role)) {
    //     return menus.push(m);
    //   }
    //   return null;
    // });

    let items = [];
    menus.map(m => {
      if (!m.parent) {
        m.isOpen = false;
        if (!m.url) m.url = 'url'
        items.push(m);
      }
      return null;
    })
    menus.map(m => {
      if (!m.parent) return null;
      for (var i = 0; i < items.length; i++) {
        if (items[i].sid === m.parent) {
          if (!items[i].children) items[i].children = [];
          items[i].children.push(m);
        }
      }
      return null;
    });
    return { items };
  }
  render() {
    if (this.state.loading) return (<p>{i18next.t('PROCESSING')}</p>)
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.state.nav} location={this.props.location} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes} /> */}
            <Container fluid className='mt-4'>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to="/login" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        {/* <AppFooter>
          <DefaultFooter />
        </AppFooter> */}
      </div>
    );
  }
}

// export default DefaultLayout;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}
export default connect(mapStateToProps)(DefaultLayout);