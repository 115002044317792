import React from 'react'
import request from './request';
import local from './local';
import locations from '../locations.json';
import queryString from 'qs';
import _ from 'lodash';
import Local from './local';
import Api from './api';
import configureStore from '../store';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
toast.configure();
let helper = {};
helper.replaceAll = (str, search, replacement) => {
    console.log("str, search, replacement", str, search, replacement)
    if (!str) str = '';
    return str.replace(new RegExp(search, 'g'), `"${replacement}"`);
}
helper.reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
helper.getLocationName = (type, id) => {
    let arr = locations[type];
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === id) return arr[i].name;
    }
    return null;
}
helper.getApiByName = (apis, name) => {
    for (var i = 0; i < apis.length; i++) {
        if (apis[i].name === name) return apis[i];
    }
    return null;
}
helper.showCustomModal = opts => {
    let { type, props } = opts;
    let store = configureStore();
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'PUSH_MODAL', data: {
                type,
                props,
                cb: rs => {
                    resolve(rs);
                }
            }
        })
    })

    // alert(content);
}
helper.alert = content => {
    let store = configureStore();
    Swal.fire(content); // VietHx add
}
helper.confirm = content => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: content,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                resolve(true);
            }
        })
    })
}
helper.callPageApi = (page, name, data) => {

    let api = helper.getApiByName(page.apis, name);
    let input = _.clone(data), url = api.url;
    switch (api.method) {
        case 'GET':
            for (var i in data) {
                if (data[i] === undefined) delete data[i];
            }
            input = Object.assign({}, {
                page: page.sid,
                api: api.name,
            }, data);
            url += `?${queryString.stringify(input)}`
            break;
        case 'PATCH':
        case 'DELETE':
            if (api.type === 'update') {
                url += `/${data.sid}?${queryString.stringify({ page: page.sid, api: api.name })}`;
                delete input.sid;
            }
            break;
        default:
            url += `?${queryString.stringify({ page: page.sid, api: api.name })}`;
            break;
    }

    return request.request(`${url}`, input, {}, api.method);
}
helper.getReportUrl = (page, name, data) => {
    let api = helper.getApiByName(page.apis, name);
    let input = _.clone(data), url = api.url;
    switch (api.method) {
        case 'GET':
            for (var i in data) {
                if (data[i] === undefined) delete data[i];
            }
            input = Object.assign({}, {
                page: page.id,
                api: api.name,
                accesstoken: Local.get('session') || 'customer'
            }, data);
            url += `?${queryString.stringify(input)}`
            break;
        default:
            break;
    }
    console.log('report url', url);
    return url;
}
helper.getApiUrl = (page, name) => {
    let api = null;
    for (var i = 0; i < page.apis.length; i++) {
        if (page.apis[i].name === name) {
            api = page.apis[i];
            break;
        }
    }
    switch (api.action) {
        default:
            return `/${api.controller}/${api.action}?page=${page.id}&api=${name}`;
    }
}
helper.getPage = sid => {
    let meta = local.get('meta');
    let pages = meta.pages;
    for (var i = 0; i < pages.length; i++) {
        if (pages[i].sid === sid) {
            // if (!Array.isArray(pages[i].buttons)) pages[i].buttons = [];
            return pages[i]
        }
    }
}
helper.toast = (type, content) => {
    toast[type](Toast(type, content), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
}
const Toast = (type, content) => {
    let icon = "fa fa-info-circle"; // info
    if (type === "success") {
        icon = "fa fa-check"
    } else if (type === "error") {
        icon = "fa fa-shield"
    } else if (type === "warning") {
        icon = "fa fa-exclamation-triangle"
    }
    return (
        <div>
            <i class={icon} aria-hidden="true"></i> &nbsp;{content}
        </div>
    )
}
helper.renameKey = (obj, old_key, new_key) => {
    if (obj[old_key])
        if (old_key !== new_key) {
            Object.defineProperty(obj, new_key,
                Object.getOwnPropertyDescriptor(obj, old_key));
            delete obj[old_key];
        }
}
helper.checkPermissionBtn = async (btnMode, pageInfo) => {
    let btnInfo = pageInfo.buttons.find(button => button.mode === btnMode)
    if (!btnInfo || !btnInfo.api) return false;

    let apiInfo = pageInfo.apis.find(api => btnInfo.api === api.name)
    if (!apiInfo || !apiInfo.url) return false;

    let rs = await Api.checkPermissionButton({ action: apiInfo.url, pageId: pageInfo.sid, name: btnInfo.api });

    if (rs && rs.havePermission) return true;

    return false;
}
helper.configs = {}
export default helper;