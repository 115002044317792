import React, { Component } from "react";
import { Button, ModalFooter } from "reactstrap";
import helper from "../services/helper";
import FormSchema from "../schema";
import Local from "../services/local";
import Loader from "./Loader";
import i18next from "i18next";
import Swal from "sweetalert2";
import api from "../services/api";
import request from "../services/request";
import config from "../services/config";

export default class FormCtrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.query.embed ? JSON.parse(this.props.query.embed) : null,
      pageInfo: null,
      error: null,
      loading: true,
      lsLanguage: [],
      dataTemp: {}
    };
  }
  componentDidMount() {
    this.loadData();
  }
  componentWillReceiveProps(next) {
    this.loadData(next);
  }
  async loadData(props) {
    if (!props) props = this.props;

    let rs = await api.getLanguage();
    // this.setState({  })
    let pageInfo = await helper.getPage(props.query.page);
    console.log("PAGE INFO", pageInfo);
    if (!pageInfo) {
      Swal.fire({
        title: "Error!",
        text: i18next.t("notFoundPage"),
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      let mutilLanguage = false;
      pageInfo.schema.map((i) => {
        if (i.isMutilLanguage) {
          mutilLanguage = true;
        }
      });
      await this.setState({
        pageInfo,
        mutilLanguage,
        mode: props.query.mode,
        lsLanguage: rs.data,
      });
      if (props.query.mode === "edit" || props.query.mode === "detail") {
        try {
          if (!props.query.sid) {
            return this.setState({ error: i18next.t("noData") });
          }
          let data = {};
          let obj = pageInfo.buttons.find(
            (i) => i.apiBasic && i.mode == "edited"
          );

          if (obj) {
            let api = helper.getApiByName(pageInfo.apis, obj.api);
            let rs = await request.request(
              api.url,
              { sid: props.query.sid },
              {},
              "POST"
            );

            if (rs) {
              if (rs.data && Array.isArray(rs.data)) {
                data = rs.data[0]
              } else {
                data = (rs && rs.data) || {};
              }
            }
          } else {
            let rs = await helper.callPageApi(pageInfo, pageInfo.read, {
              queryInput: JSON.stringify({ sid: props.query.sid }),
            });
            data = (rs && rs.data[0]) || {};
          }
          if (props.query.embed) {
            Object.assign(data, JSON.parse(props.query.embed));
          }

          let checkDateRange = this.state.pageInfo.schema.filter(i => i.widget == 'DateRange')
          if (checkDateRange && checkDateRange.length > 0) {
            checkDateRange.map(item => {
              let fieldArr = item.field.split('-');
              data[item.field] = []
              data[item.field].push(data[fieldArr[0]])
              data[item.field].push(data[fieldArr[1]])
              delete data[fieldArr[0]]
              delete data[fieldArr[1]]
            })
          }
          let checkLocation = this.state.pageInfo.schema.filter(i => i.widget == 'Location')
          if (checkLocation && checkLocation.length > 0) {
            checkLocation.map(item => {
              let fieldArr = item.field.split('/');
              data[item.field] = `${data[fieldArr[0]]}/${data[fieldArr[1]]}`
              delete data[fieldArr[0]]
              delete data[fieldArr[1]]
            })
          }
          this.setState({ data });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  async onSubmit() {
    this.onButtonClick();
  }

  async onButtonClick(btnInfo) {
    if (!btnInfo) {
      for (var i = 0; i < this.state.pageInfo.buttons.length; i++) {
        if (this.state.pageInfo.buttons[i].mode === this.props.query.mode) {
          btnInfo = this.state.pageInfo.buttons[i];
          break;
        }
      }
    }
    if (btnInfo) {
      try {
        // if (btnInfo.type === "submit" && btnInfo.action === "api") {

        if (this.formRef) {
          let error = this.formRef.checkError();
          if (error >= 0)
            return helper.alert(
              `${i18next.t("data_incorrect_please_check_again")} ${
              this.state.pageInfo.schema[error].name
              }`
            );
        }
        // }

        let data = Object.assign({}, this.state.data);
        let checkDateRange = this.state.pageInfo.schema.filter(i => i.widget == 'DateRange')
        if (checkDateRange && checkDateRange.length > 0) {
          checkDateRange.map(item => {
            let value = data[item.field];
            let fieldArr = item.field.split('-');
            data[fieldArr[0]] = value[0]
            data[fieldArr[1]] = value[1]
            delete data[item.field]
          })
        }
        let checkLocation = this.state.pageInfo.schema.filter(i => i.widget == 'Location')
        if (checkLocation && checkLocation.length > 0) {
          checkLocation.map(item => {
            let fieldArr = item.field.split('/');
            let value = data[item.field].split('/')
            data[fieldArr[0]] = value[0]
            data[fieldArr[1]] = value[1]
            delete data[item.field]
          })
        }
        if (this.state.mode !== "edit" && this.state.mutilLanguage) {
          let arrSchemaMutil = [],
            codeLg = [];
          this.state.pageInfo.schema.map((i) => {
            i.isMutilLanguage && arrSchemaMutil.push(i);
          });
          this.state.lsLanguage.map((lg) => codeLg.push(lg.code));
          arrSchemaMutil.map((item) => {
            if (!data[item.field] || typeof data[item.field] !== 'object') data[item.field] = {};
            codeLg.map((code) => {
              if (data[code][item.field])
                data[item.field][code] = data[code][item.field];
            });
          });
          codeLg.map((code) => delete data[code]);
        }
        if (btnInfo.confirm) {
          let confirmText = btnInfo.confirm;
          for (var f in data) {
            confirmText = helper.replaceAll(
              confirmText,
              "#" + f + "#",
              data[f]
            );
          }
          let rs = await helper.confirm(confirmText);
          console.log("confirm", rs);
        }
        if (this.props.query.embed && btnInfo.embedUrl) {
          data = Object({}, data, JSON.stringify(this.props.query.embed));
        }
        Object.keys(data).forEach((k) => {
          if (
            Array.isArray(data[k]) &&
            data[k].length > 0
          ) {
            data[k].map((item) => {
              if (item.key) {
                this.renameKey(item, "key", "sid");
              }
              if (item.label) {
                this.renameKey(item, "label", "name");
              }
              if (item.id) delete item.id;
            });
          }
        });
        let response = {};
        if (btnInfo.apiBasic) {
          this.setState({ isDisableBtn: true })
          let api = helper.getApiByName(this.state.pageInfo.apis, btnInfo.api);
          response = await request.request(api.url, data, {}, "POST");
        } else {
          this.setState({ isDisableBtn: true })
          response = await helper.callPageApi(
            this.state.pageInfo,
            btnInfo.api,
            data
          );
        }
        this.setState({ isDisableBtn: false })
        if (response && (response.sid || response.errorCode == 0)) {
          if (btnInfo.backOnDone) {
            window.history.back();
          } else if (this.props.showModal) {
            if (this.props.onChange) {
              this.props.onChange({
                isShowModal: false,
                message: response.errorMsg || i18next.t("successfully"),
              });
            }
          }
        } else {
          this.setState({ isDisableBtn: false })
          helper.toast("error", response.errorMsg || response.message || i18next.t("invalidParam"));

        }
      } catch (err) {
        this.setState({ isDisableBtn: false })
        console.warn(`error ${err}`);
      }
    } else {
      helper.alert(i18next.t("no_have_button"));
    }
  }
  async setData(data) {
    let arrSchemaMutil = [],
      codeLg = [];
    this.state.pageInfo.schema.map((i) => {
      i.isMutilLanguage && arrSchemaMutil.push(i);
    });
    if (arrSchemaMutil.length > 0) {
      this.state.lsLanguage.map((lg) => codeLg.push(lg.code));
      arrSchemaMutil.map((item) => {
        data[item.field] = {};
        codeLg.map((code) => {
          if (data[code][item.field])
            data[item.field][code] = data[code][item.field];
        });
      });
      codeLg.map((code) => delete data[code]);
    }
    await this.setState({ data });
  }
  renameKey(obj, old_key, new_key) {
    if (old_key !== new_key) {
      Object.defineProperty(
        obj,
        new_key, // modify old key
        // fetch description from object
        Object.getOwnPropertyDescriptor(obj, old_key)
      );
      delete obj[old_key]; // delete old key
    }
  }

  render() {
    if ((this.state.mode === "edit" || this.state.mode === "detail") && !this.state.data) return <Loader />;

    if (this.state.error)
      return <p className="text-danger"> {this.state.error}</p>;

    if (!this.state.pageInfo) return null;

    return (
      <FormSchema
        ref={(ref) => {
          this.formRef = ref;
        }}
        mode={this.state.mode}
        schema={this.state.pageInfo.schema}
        group={this.state.pageInfo.group}
        data={this.state.data || {}}
        onChange={async (data) => {
          if (this.state.mode === "edit" && this.state.mutilLanguage) {
            this.setData(data);
          } else {
            for (let i = 0; i < this.state.pageInfo.schema.length; i++) {
              let item = this.state.pageInfo.schema[i];
              if (item.widget === 'SelectModel') {
                if (item.isFillDtOtherField && data[item.field]) {
                  if (typeof data[item.field] === 'string' && data[item.field] !== this.state.dataTemp[item.field]) {
                    let api = helper.getApiByName(this.state.pageInfo.apis, item.funcGetData)
                    let rs = await request.request(api.url, { [item.fieldQuery]: data[item.field] }, {}, api.method)
                    if (rs && rs.data) {
                      data = rs.data
                      let { dataTemp } = this.state, dt = {};
                      dt = {
                        ...dataTemp,
                        ...{ [item.field]: data[item.field] }
                      }

                      this.setState({ dataTemp: dt })
                    }
                  }
                  // else {
                  //   data = {}
                  //   data[item.field] = {}
                  // }
                }

              }
            }
            // debugger
            this.setState({ data });
          }
        }}
        onSubmit={this.onSubmit.bind(this)}
      >
        <br />

        <ModalFooter>
          {this.state.pageInfo.buttons.map((item, index) => {
            if (this.state.mode === item.mode && this.state.mode !== "detail") {
              let url = "";
              var i = 0;
              switch (item.action) {
                case "url":
                  url = item.url.replace("$", this.state.data);
                  for (i in this.state.data) {
                    url = helper.replaceAll(
                      url,
                      "#" + i + "#",
                      this.state.data[i]
                    );
                  }
                  for (i in this.query) {
                    url = helper.replaceAll(
                      url,
                      "@" + i + "@",
                      this.props.query[i]
                    );
                  }
                  return (
                    <a
                      key={index}
                      href={url}
                      className={`btn btn-${item.color} mr-1`}
                    >
                      <i className={item.icon} /> {item.title}
                    </a>
                  );
                case "api":
                case "formModal":
                  return (
                    <Button
                      disabled={this.state.isDisableBtn}
                      key={index}
                      className="mr-1"
                      color={item.color}
                      onClick={() => {
                        this.onButtonClick(item);
                      }}
                    >
                      <i className={item.icon} /> {item.title}
                    </Button>
                  );
                case "report":
                  url = item.url.replace("$", this.state.data);
                  for (i in this.state.data) {
                    url = helper.replaceAll(
                      url,
                      "#" + i + "#",
                      this.state.data[i]
                    );
                  }
                  for (i in this.query) {
                    url = helper.replaceAll(
                      url,
                      "@" + i + "@",
                      this.props.query[i]
                    );
                  }
                  url += "&accesstoken=" + Local.get("session");
                  return (
                    <a
                      key={index}
                      href={url}
                      className={`btn btn-${item.color} mr-1`}
                    >
                      <i className={item.icon} /> {item.title}
                    </a>
                  );
                default:
                  return null;
              }
            }
            return null;
          })}
          <Button type="button" className="btn btn-danger" onClick={this.props.onCloseModal}>
            <i className="fa fa-times" />
            &nbsp;
            {this.state.mode === "detail" ? i18next.t("close") : i18next.t("cancel")}
          </Button>
        </ModalFooter>
      </FormSchema>
    );
  }
}
