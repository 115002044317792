import React, { Component } from 'react';
import {
    Input,
    InputGroupAddon,
    InputGroup,
    Button
} from 'reactstrap';
import helper from "../services/helper";
import { GoogleMap, Marker, withScriptjs, withGoogleMap, } from "react-google-maps"
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
const _ = require("lodash");
const { compose, withProps, lifecycle } = require("recompose");

const MapWithAMarker = lifecycle({
    componentWillMount() {
        const refs = {}

        this.setState({
            bounds: null,
            center: {
                lat: this.props.lat, lng: this.props.lng
            },
            markers: [],
            onMapMounted: ref => {
                refs.map = ref;
            },
            onBoundsChanged: () => {
                this.setState({
                    bounds: refs.map.getBounds(),
                    center: refs.map.getCenter(),
                })
            },
            onSearchBoxMounted: ref => {
                refs.searchBox = ref;
            },
            onPlacesChanged: () => {
                const places = refs.searchBox.getPlaces();
                const bounds = new window.google.maps.LatLngBounds();

                places.forEach(place => {
                    if (place.geometry.viewport) {
                        bounds.union(place.geometry.viewport)
                    } else {
                        bounds.extend(place.geometry.location)
                    }
                });
                const nextMarkers = places.map(place => ({
                    position: place.geometry.location,
                }));
                const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                this.setState({
                    center: nextCenter,
                    markers: nextMarkers,
                });
                nextMarkers.map((marker, index) => {
                    console.log('marker.position  ' + index)
                    if (this.props.onChange) {
                        let val = `${marker.position.lat()}/${marker.position.lng()}`
                        this.props.onChange(val)
                    }
                })
                refs.map.fitBounds(bounds);
            },
        })
    },
})(withScriptjs(withGoogleMap(props =>
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={14}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        center={props.center}
        onBoundsChanged={props.onBoundsChanged}
    >
        {props.markers.map((marker, index) =>
            <Marker
                key={index}
                position={marker.position}
                //position={{ lat: props.lat, lng: props.lng }}
                draggable={true}
                onDragEnd={evt => {
                    if (props.onChange) {
                        let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`
                        props.onChange(rs);
                    }
                }}
            />
        )}
        <Marker
            //key={index} 
            // position={marker.position} 
            position={{ lat: props.lat, lng: props.lng }}
            draggable={true}
            onDragEnd={evt => {
                if (props.onChange) {
                    let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`
                    props.onChange(rs);
                }
            }}
        />

        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Customized your placeholder"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `500px`,
                    height: `32px`,
                    marginTop: `8px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
    </GoogleMap>
)));
class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: props.schema || {},
            rs: [21.932065, 96.099168]
        }
    }
    componentWillMount() {
        this.setState({ value: this.props.value })
        let { value, schema } = this.state;
        if (value) {
            this.state.rs = this.props.value.split('/');
            if (!isNaN(Number(this.state.rs[0])) && !isNaN(Number(this.state.rs[1]))) {
                this.state.rs[0] = Number(this.state.rs[0]);
                this.state.rs[1] = Number(this.state.rs[1]);
            }
        }
    }
    componentDidMount() {
        this.setState({ value: this.props.value })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value })
    }
    render() {
        let { value, schema } = this.state;
        if (value) {
            this.state.rs = this.props.value.split('/');
            if (!isNaN(Number(this.state.rs[0])) && !isNaN(Number(this.state.rs[1]))) {
                this.state.rs[0] = Number(this.state.rs[0]);
                this.state.rs[1] = Number(this.state.rs[1]);
            }
        }
        return (<div>
            <InputGroup>
                <Input type="text" id="name" placeholder="Enter a GPS. Example: 21.932065/96.099168" required value={this.props.value}
                    onChange={evt => {
                        if (this.props.onChange) {
                            this.props.onChange(evt.target.value);
                        }
                    }} />
                <InputGroupAddon addonType="append">
                    <Button type="button" color="default">Enter a location</Button>
                </InputGroupAddon>
            </InputGroup>

            <div style={{ marginTop: '15px' }}>
                <MapWithAMarker
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${helper.configs.GOOGLE_API.subConfigs.MAP_API.value}&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    lat={this.state.rs[0]}
                    lng={this.state.rs[1]}
                    onChange={val => {
                        if (this.props.onChange) {
                            this.props.onChange(val);
                        }
                    }}
                />
                {/* <MapWithASearchBox /> */}
            </div>
        </div>)
    }
}

export default Location;
