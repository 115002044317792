import React, { Component } from 'react';
import { AppSwitch } from '@coreui/react'
class Checkbox extends Component {
    constructor(props) {
        super(props);
        // if (props.value === null || props.value === undefined) {
        //     if (props.onChange) {
        //         props.onChange(true);
        //     }
        // }
        debugger
        this.state = {
            value: !!props.value ? false : true
        };
    }
    componentWillReceiveProps(next) {
        this.setState({ checked: next.value });
    }
    render() {
        return (<div>
            <AppSwitch
                color={'success'}
                disabled={this.props.schema.disabled || false}
                onChange={e => {
                    if (this.props.onChange) {
                        this.props.onChange(e.target.checked);
                    }
                }}
                checked={this.props.value} />
        </div>)
    }
}

export default Checkbox;