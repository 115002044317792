import Text from './Text';
import SingleModel from './SingleModel';
import Checkbox from './Checkbox';
import Date from './Date';
import Time from './Time';
import Location from './Location';
import Image from './Image';
import TextArea from './TextArea';
import RichText from './RichText';
import Enum from './Enum';
import ArrayImage from './ArrayImage';
import ArrayModel from './ArrayModel';
import Password from './Password';
import Upload from './Upload';
import Captcha from './Captcha';
import TabsLanguage from './TabsLanguage';
import SelectModel from './SelectModel';
import TableGenerate from './TableGenerate';
import SelectMultiple from './SelectMultiple'
import CkEditorSchema from './CkEditorSchema'
import TableCampaign from './TableCampaign'
import SearchSelect from './SearchSelect'
import DateRange from "./DateRange"
import TextJson from "./TextJson"
import DateTime from "./DateTime"
export default {
    Time,
    DateTime,
    Captcha,
    Upload,
    Password,
    ArrayModel,
    ArrayImage,
    Enum,
    RichText,
    TextArea,
    Image,
    Location,
    Text,
    SingleModel,
    Checkbox,
    Date,
    TabsLanguage,
    SelectModel,
    TableCampaign,
    TableGenerate,
    SelectMultiple,
    CkEditorSchema,
    SearchSelect,
    DateRange,
    TextJson
};
