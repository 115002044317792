import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from "react-table";
import {
    Button,
    InputGroup,
    InputGroupAddon,
    ButtonGroup
} from 'reactstrap';
import helper from '../services/helper';
import Checkbox from './Checkbox';
import _ from 'lodash';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
class ArrayModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: [],
            names: [],
            loading: true,
            search: '',
            pageId: props.schema.pageId,
            schema: props.schema,
            count: 0,
            columns: [],//this.calculateColumns(props.schema)
            output: _.clone(props.value || []),
            nPages: 0,
            display: null,
            mode: 'select'
        };
        this.init(props.schema.pageId, props.schema, _.clone(props.value || []));
    }
    itemsPerPage = 10;
    pageInfo = null;
    componentWillReceiveProps(next) {
        if (!next.value) {
            return this.setState({ names: [], output: [] });
        }
        if (!_.isEqual(this.state.output, next.value)) {
            this.fetchItemName(this.pageInfo, this.state.schema, next.value);
        }
        this.setState({ output: _.clone(next.value) });
    }
    async init(pageId, schema, output) {
        this.pageInfo = helper.getPage(pageId);
        this.fetchItemName(this.pageInfo, schema, output);
    }

    toggle(mode) {
        this.setState({
            mode,
            modal: !this.state.modal
        });
    }
    async fetchData(tbl) {
        let filter = {}, sort = null;
        if (tbl.filtered) {
            tbl.filtered.map(f => {
                switch (f.code) {
                    case 'code':
                        filter.code = Number(f.value) || 0;
                        break;
                    default:
                        filter[f.code] = { contains: f.value };
                        break;
                }
                return null;
            })
        }
        if (tbl && tbl.sorted) {
            sort = [];
            tbl.sorted.map(s => {
                sort.push({
                    [s.code]: s.desc ? 'desc' : 'asc'
                });
                return null;
            })
        }
        if (this.state.mode === 'view') {
            if (filter.code) {
                if (!_.includes(this.state.output, filter.code)) {
                    filter.code = 0;
                }
            } else {
                filter.code = this.state.output;
            }
        }
        try {
            let rs = await helper.callPageApi(this.pageInfo, this.state.schema.api, { sort, queryInput: JSON.stringify(filter), limit: tbl.pageSize, skip: tbl.pageSize * tbl.page });
            rs.data = this.calculateCheck(rs.data, this.state.output);
            let column = this.calculateColumns(rs.data)
            this.setState({ data: rs.data, count: rs.count, loading: false, columns: column, nPage: Math.ceil(rs.count / tbl.pageSize) })
        } catch (err) {
            console.error(err);
        }
    }
    async fetchItemName(pageInfo, schema, output) {
        debugger
        if (!pageInfo || !schema || !output) return;
        let filter = {};
        filter.code = output;
        try {
            let rs = await helper.callPageApi(pageInfo, schema.api, { queryInput: JSON.stringify(filter), select: 'name' });
            let display = [];
            rs.data.map(d => {
                return display.push(d.name);
            });
            this.setState({ names: rs.data, display: _.join(display, '-') });
        } catch (err) {
            console.error(err);
        }
    }
    calculateCheck(data, output) {
        data.map(d => {
            if (_.includes(output, d.code)) return d.checked = true;
            return d.checked = false;
        });
        return data;
    }

    onCheckboxChanged(row, e) {
        let output = this.state.output.splice(0);
        if (e) {
            if (!_.includes(output, row.row.code)) output.push(row.row.code);
        } else {
            let tmp = [];
            output.map(o => {
                if (o !== row.row.code) {
                    tmp.push(o);
                }
                return null;
            });
            output = tmp;
        }

        let data = this.calculateCheck(this.state.data, output);
        this.setState({ data, output });
    }
    calculateColumns(data) {
        let cols = [];
        let names = ["code", typeof data[0].name == "string" ? "name" : "title"]
        names.map(n => {
            let arr = n.split('$$');

            cols.push({
                Header: ((n || arr[1]) === "code") ? "Code" : (arr[1] && this.capitalizeFirstLetter(arr[1]) || this.capitalizeFirstLetter(n)),
                accessor: arr[0],
            });
            return null;
        });
        cols.push({
            Header: 'Choose',
            accessor: 'checked',
            filterable: false,
            Cell: row => {
                let val = false;
                for (var i = 0; i < this.state.data.length; i++) {
                    if (this.state.data[i].code === row.row.code) {
                        val = this.state.data[i].checked || false;
                    }
                }
                return <div>
                    {this.state.mode === 'select' ? <Checkbox value={val} onChange={e => {
                        this.onCheckboxChanged(row, e);
                    }} /> : null}
                </div>
            }
        })
        return cols;
    }
    capitalizeFirstLetter(string) {

        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    confirm() {
        this.onChange(this.state.output);
        this.fetchItemName(this.pageInfo, this.state.schema, this.state.output);
        this.toggle();
    }
    onChange(dt) {
        console.log('dt', dt);
        if (this.props.onChange) {
            this.props.onChange(dt);
        }
    }
    onRemoveClick(code) {
        let output = [], names = [];
        for (var i = 0; i < this.state.names.length; i++) {
            if (this.state.names[i].code !== code) {
                output.push(this.state.names[i].code);
                names.push(this.state.names[i]);
            }
        }
        this.setState({ output, names });
        this.onChange(output);
    }
    render() {
        return (<div>
            <InputGroup>
                <div className='form-control select-container overflow-hidden'>
                    {/* {this.state.names.map((item, index) => {
                        return item ? <ButtonGroup size='sm' key={index} className='mr-2 select-item'>
                            <Button color='danger' type='button' size='sm' onClick={() => { this.onRemoveClick(item.code) }}><i className='fa fa-remove' /></Button>
                            <Button color='default' type='button' size='sm'>{item.name}</Button>
                        </ButtonGroup> : null
                    }
                    )} */}
                    {
                        this.state.names.length > 0 ? this.state.names.length + " item selected" : ""
                    }
                </div>
                <InputGroupAddon addonType="append" >
                    <Button disabled={this.props.disabled} type='button' color="primary" onClick={() => { this.toggle('select') }}><i className='fa fa-plus' /></Button>
                </InputGroupAddon>
            </InputGroup>
            <Modal isOpen={this.state.modal} fade={false} size={'lg'}>
                <ModalHeader>Choose</ModalHeader>
                <ModalBody>
                    <ReactTable
                        previousText={i18next.t('previuos')}
                        nextText={i18next.t('next')}
                        pageText={i18next.t('page')}
                        rowsText={i18next.t('record')}
                        ofText={i18next.t('outOff')}
                        data={this.state.data}
                        loading={this.state.loading}
                        manual
                        onFetchData={this.fetchData.bind(this)}
                        pages={this.state.nPage}
                        columns={this.state.columns}
                        defaultPageSize={this.itemsPerPage}
                        className="-striped -highlight"
                    />
                </ModalBody>
                <ModalFooter>
                    {this.state.mode === 'select' ? <React.Fragment>
                        <Button color="primary mr-1" onClick={this.confirm.bind(this)}><Trans i18nKey="confirm"></Trans></Button>
                        <Button color="secondary" onClick={this.toggle.bind(this)}><Trans i18nKey="cancel"></Trans></Button>
                    </React.Fragment>
                        : <Button color="secondary" onClick={this.toggle.bind(this)}><Trans i18nKey="close"></Trans></Button>}
                </ModalFooter>
            </Modal>
        </div>)
    }
}

export default ArrayModel;