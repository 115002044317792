import React, { Component } from 'react';
import Widgets from './Widgets';
import { FormGroup, Label, Col, Row } from 'reactstrap'
import TableGenerate from './TableGenerate'
import ReactTooltip from "react-tooltip";


class Base extends Component {
    error() {
        if (this.isInvalid()) {
            return <p className='text-danger'>
                <b> {
                    this.props.schema.name
                }
                    &nbsp;is required!</b>
            </p>
        }
        /**
     * @editedBy: TamDV @EditedAt: 01/06/2020
     * @description update check phone, email
    */
        if (this.props.value) {
            if (this.isInvalidRegex()) {
                return <p className='text-danger'>
                    <b> {
                        this.props.schema.name
                    }
                    &nbsp;is invalid!</b>
                </p>
            }
        }
        if (this.isMaxLength()) {
            return <p className='text-danger'>
                <b>Length of {
                    this.props.schema.name
                }
                    &nbsp;is must smaller {this.props.schema.maxLength}</b>
            </p>
        }
    }


    isInvalid() {
        if (this.props.schema.required && (this.props.value === null || this.props.value === undefined || this.props.value === ''))
            return true;

    }
    /**
     * @editedBy: TamDV @EditedAt: 19/06/2020
     * @description update check phone, email
    */
    isInvalidRegex() {
        // const phoneRegex = ^(09)([0-9]{7,9})$;
        if (this.props.schema.regex) {
            const regex = new RegExp(this.props.schema.regex);
            return !regex.test(this.props.value)
        }
    }

    isMaxLength() {
        if (this.props.schema.maxLength > 0) {
            console.log(this.props.value.length + " " + this.props.schema.maxLength)
            return this.props.value.length > Number(this.props.schema.maxLength)
        }

    }

    render() {
        if (this.props.schema.widget === 'TableGenerate') {
            return <TableGenerate schema={this.props.schema} value={this.props.value} onChange={this.props.onChange} />
        } else {
            let Widget = Widgets[this.props.schema.widget];
            console.log('Widget', Widget)
            if (!Widget) {
                return <p>Invalid type {
                    this.props.schema.widget
                }</p>
            }

            return <Col md='12'>
                <FormGroup row >
                    <Col className='form-label-horizontal' md='3'>
                        <Label>
                            <b> {
                                this.props.schema.name
                            }
                                {
                                    this.props.schema.sub ? <i style={{ "font-size": "8px" }}>({this.props.schema.sub})</i> : ''
                                }
                                &nbsp;{
                                    this.props.schema.required ? '(*)' : ''
                                }

                              &nbsp;  {
                                    this.props.schema.info ? <span><i data-tip={this.props.schema.info} class="fa fa-info-circle"></i><ReactTooltip place="bottom" type="light" border="true" /></span> : ''
                                }</b>
                        </Label>
                    </Col>
                    <Col md='8'>
                        <Widget onChange={
                            this.props.onChange
                        }
                            valueFilter={this.props.valueFilter}
                            value={
                                this.props.value === undefined ? this.props.schema.default : this.props.value
                            }
                            ARRAY={
                                this.props.schema.items || []
                            }
                            schema={
                                this.props.schema
                            }
                            invalid={
                                this.props.mode !== "detail" && this.isInvalid()
                            } /> {
                            this.props.mode !== "detail" && this.error()
                        }
                    </Col>
                </FormGroup>
            </Col>

        }
    }
}

export default Base;
