// import React from 'react';
// import CKEditor from "react-ckeditor-component";

// export default class CkEditorSchema extends React.Component {
//     constructor(props) {
//         super(props)
//         this.onChange = this.onChange.bind(this);
//     }
//     onChange(evt) {
//         if (this.props.onChange) {
//             
//             console.log("here " + evt.editor.getData())
//             this.props.onChange(evt.editor.getData())
//         }
//     }
//     render() {
//         return (
//             <CKEditor
//                 activeClass="p10"
//                 content={this.props.value}
//                 events={{
//                     "change": this.onChange
//                 }}
//             />
//         )
//     }
// }

/**
  * @editedBy: TamDV @EditedAt:24/06/2020
  * @description fix editor
*/
import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
const CkEditorSchema = (props) => {

    const handleEditorChange = (content, editor) => {
        props.onChange(content)
    }

    return (

        <Editor
            initialValue={props.value}
            init={{
                height: 250,
                // menubar: 'file edit view insert format tools table help',
                menubar: false,
                toolbar: 'undo redo | bold italic underline code strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
            }}
            onEditorChange={handleEditorChange}
        />
    )
}

export default CkEditorSchema
