/* eslint no-eval: 0 */
import React, { Component } from 'react';
import helper from '../services/helper';
import Base from './Base';
import { Card, CardBody, CardGroup, Col, Row, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import api from '../services/api';
import Loader from '../controls/Loader';

class FormSchema extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lsLanguage: [],
            activeTab: '',
            data: props.data || {}
        }
        this.init();
        this.checkDisable();
    }
    async componentWillReceiveProps(next) {
        let data = next.data
        this.state.lsLanguage.map(item => {
            if (!data[item.code]) data[item.code] = {}
        })
        await this.setState({ data })
    }
    async init() {
        let rs = await api.getLanguage();
        rs.data.map(item => {
            this.state.data[item.code] = {}
        })
        await this.setState({ lsLanguage: rs.data, activeTab: rs.data[0].code })

    }
    toggleTab(code) {
        this.setState({ activeTab: code })
    }
    checkDisable() {
        this.props.schema.map((s, i) => {
            if (s.disabled && s.disableConditions) {
                if (s.disableConditions.split(',').includes(this.props.mode)) {
                    this.props.schema[i].disabled = true
                }
                else {
                    this.props.schema[i].disabled = false
                }
            }
            if (this.props.mode === "detail") {
                this.props.schema[i].disabled = true

            }
        });
    }
    checkError() {
        for (let i = 0; i < this.props.schema.length; i++) {
            let schema = this.props.schema[i];
            let value = this.state.data[this.props.schema[i].field];
            if (this.isInvalid(schema, value) || this.isInvalidRegex(schema, value) || this.isMaxLength(schema, value)) {
                return i;
            }
        }
        return -1;
    }
    onSubmit() {
        console.log('on submit');
        let error = this.checkError();
        console.log('error', error);
        if (error >= 0) return helper.alert(`the data incorrect. Please check again ${this.props.schema[error].name}`);
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }
    isInvalidRegex(schema, value) {
        if (schema.regex && value) {
            const regex = new RegExp(schema.regex, "g");
            return !regex.test(value)
        }
    }

    isMaxLength(schema, value) {
        if (schema.maxLength > 0) {
            console.log(value.length + " " + schema.maxLength)
            return value.length > Number(schema.maxLength)
        }

    }
    isInvalid(schema, value) {
        if (schema.required && (value === null || value === undefined || value === '')) return true;
        return false;
    }
    render() {
        if (!this.props.schema) return <p>not </p>

        const mode = this.props.mode || ''// TuPT: No deletion allowed
        //convert default value
        this.props.schema.map(s => {
            if (this.state.data[s.field] === undefined && s.default) this.state.data[s.field] = s.default;
            return null;
        })
        if (this.props.group && this.props.group.length !== 0) {
            this.props.group.map((gr, idx) => {
                gr.schema = []
                this.props.schema.map((item, idx) => {
                    if (gr.key === item.group) {
                        gr.schema.push(item)
                    }
                })
            })
            let group = this.props.group.filter(i => (i.schema && i.schema.length > 0))
            return (<form autoComplete='off' onSubmit={evt => {
                evt.preventDefault();
                if (this.props.onSubmit) {
                    this.props.onSubmit();
                }
            }}>
                {group.map((gr, idx) => {
                    return <Row key={idx} style={{ marginBottom: "30px" }}>
                        <Col md="12">
                            <CardGroup>
                                <Card>
                                    <CardHeader>
                                        <div className='pull-left'>
                                            <h5 className='mb-0'>
                                                {gr.name}
                                            </h5>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {
                                            gr.schema.map((comp, index) => {
                                                if (comp.hideExpression) {
                                                    let str = comp.hideExpression;
                                                    // for (var i in this.state.data) {
                                                    //     str = helper.replaceAll(str, i, this.state.data[i]);
                                                    // }
                                                    if (eval(str)) {
                                                        if (comp.required) comp.required = false
                                                        return null;
                                                    }
                                                }
                                                let valueFilter = '', showBase = true, value = '', fieldArr = [], maxLength;
                                                /**
                                                  * @editedBy: TamDV @EditedAt: 01/06/2020
                                                  * @description update check phone, email
                                                  */
                                                let regex = '';
                                                if (comp.regex) {
                                                    regex = this.props.data[comp.regex]
                                                }
                                                /**
                                                 * @editedBy: TamDV @EditedAt: 03/06/2020
                                                 * @description update check max length
                                                 */
                                                if (comp.maxLength) {
                                                    maxLength = this.props.data[comp.maxLength]
                                                }
                                                /*tam*/
                                                if (comp.filterBy && !comp.filterBy.includes('=')) {
                                                    valueFilter = this.props.data[comp.filterBy]
                                                }
                                                if (comp.filterBy && comp.filterBy.includes('=')) {
                                                    if (comp.filterBy.split('==')[1] == this.props.data[comp.filterBy.split('==')[0]]) {
                                                        showBase = true
                                                    } else showBase = false
                                                }
                                                if (comp.field.includes('.')) {
                                                    fieldArr = comp.field.split('.')
                                                    if (this.props.data[fieldArr[0]]) value = this.props.data[fieldArr[0]][fieldArr[1]]

                                                } else {
                                                    value = this.props.data[comp.field]
                                                }
                                                if (showBase) {
                                                    return <Base
                                                        key={index}
                                                        schema={comp}
                                                        mode={this.props.mode}
                                                        valueFilter={valueFilter}
                                                        onChange={e => {

                                                            let dt = Object.assign({}, this.props.data);
                                                            if (comp.field.includes('.')) {
                                                                dt[fieldArr[0]] = {}
                                                                dt[fieldArr[0]][fieldArr[1]] = e
                                                            }
                                                            else dt[comp.field] = e;

                                                            if (this.props.onChange) {
                                                                this.props.onChange(dt);
                                                            }
                                                        }}
                                                        value={value}
                                                    />
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                })}
                {this.props.children}
            </form>)
        } else {
            let checkMutil = false, arrMutil = [], arrUnmutil = []
            this.props.schema.map(item => {
                if (item.isMutilLanguage) {
                    checkMutil = true
                    arrMutil.push(item)
                }
                if (!item.isMutilLanguage) {
                    arrUnmutil.push(item)
                }
            })
            if (!checkMutil) {
                return (<form autoComplete='off' onSubmit={evt => {
                    evt.preventDefault();
                    if (this.props.onSubmit) {
                        this.props.onSubmit();
                    }
                }}>
                    {this.props.schema.map((comp, index) => {
                        if (comp.hideExpression) {
                            let str = comp.hideExpression;
                            // for (var i in this.state.data) {
                            //     str = helper.replaceAll(str, i, this.state.data[i]);
                            // }
                            if (eval(str)) {
                                if (comp.required) comp.required = false
                                return null;
                            }
                        }
                        let valueFilter = '', showBase = true, value = '', fieldArr = [];
                        if (comp.filterBy && !comp.filterBy.includes('=')) {
                            valueFilter = this.props.data[comp.filterBy]
                        }
                        if (comp.filterBy && comp.filterBy.includes('=')) {
                            if (comp.filterBy.split('==')[1] == this.props.data[comp.filterBy.split('==')[0]]) {
                                showBase = true
                            } else showBase = false
                        }
                        if (comp.field.includes('.')) {
                            fieldArr = comp.field.split('.')
                            if (this.props.data[fieldArr[0]]) value = this.props.data[fieldArr[0]][fieldArr[1]]

                        } else {
                            value = this.props.data[comp.field]
                        }
                        if (showBase) {
                            return <Base
                                key={index}
                                schema={comp}
                                valueFilter={valueFilter}
                                onChange={e => {
                                    let dt = Object.assign({}, this.props.data);
                                    if (comp.field.includes('.')) {
                                        dt[fieldArr[0]] = {}
                                        dt[fieldArr[0]][fieldArr[1]] = e
                                    }
                                    else dt[comp.field] = e;

                                    if (this.props.onChange) {
                                        this.props.onChange(dt);
                                    }
                                }}
                                value={value}
                            />
                        } else {
                            return null;
                        }
                    })}
                    {this.props.children}
                </form>)
            } else {
                if (this.state.lsLanguage.length === 0) return <Loader />
                this.state.lsLanguage.map(language => {
                    language.schema = arrMutil
                    if (this.props.mode !== 'edit') {
                        language.data = {}
                    } else {
                        arrMutil.map(i => {
                            this.state.data[language.code][i.field] = this.state.data[i.field][language.code]
                        })
                        language.data = this.state.data[language.code]
                    }
                    Object.keys(language.data).map(k => !language.data[k] && delete language.data[k])
                })
                return (<form autoComplete='off' onSubmit={evt => {
                    evt.preventDefault();
                    if (this.props.onSubmit) {
                        this.props.onSubmit();
                    }
                }}>
                    {arrUnmutil.map((comp, index) => {
                        if (comp.hideExpression) {
                            let str = comp.hideExpression;
                            // for (var i in this.state.data) {
                            //     str = helper.replaceAll(str, i, this.state.data[i]);
                            // }
                            if (eval(str)) {
                                if (comp.required) comp.required = false
                                return null;
                            }
                        }
                        let valueFilter = '', showBase = true, value = '', fieldArr = [];
                        if (comp.filterBy && !comp.filterBy.includes('=')) {
                            valueFilter = this.props.data[comp.filterBy]
                        }
                        if (comp.filterBy && comp.filterBy.includes('=')) {
                            if (comp.filterBy.split('==')[1] == this.props.data[comp.filterBy.split('==')[0]]) {
                                showBase = true
                            } else showBase = false
                        }
                        if (comp.field.includes('.')) {
                            fieldArr = comp.field.split('.')
                            if (this.props.data[fieldArr[0]]) value = this.props.data[fieldArr[0]][fieldArr[1]]

                        } else {
                            value = this.props.data[comp.field]
                        }
                        if (showBase) {
                            return <Base
                                key={index}
                                schema={comp}
                                valueFilter={valueFilter}
                                onChange={e => {

                                    let dt = Object.assign({}, this.props.data);
                                    if (comp.field.includes('.')) {
                                        dt[fieldArr[0]] = {}
                                        dt[fieldArr[0]][fieldArr[1]] = e
                                    }
                                    else dt[comp.field] = e;

                                    if (this.props.onChange) {
                                        this.props.onChange(dt);
                                    }
                                }}
                                value={value}
                            />
                        } else {
                            return null;
                        }
                    })}
                    <Nav tabs>
                        {this.state.lsLanguage.map((i, idx) => <NavItem key={idx}>
                            <NavLink active={this.state.activeTab === i.code}
                                onClick={() => { this.toggleTab(i.code) }}>{i.name}</NavLink>
                        </NavItem>)}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.state.lsLanguage.map((lg, idx) => <TabPane key={idx} tabId={lg.code}>
                            {lg.schema.map((comp, index) => {
                                // 
                                if (comp.hideExpression) {
                                    let str = comp.hideExpression;
                                    // for (var i in this.state.data) {
                                    //     str = helper.replaceAll(str, i, this.state.data[i]);
                                    // }
                                    if (eval(str)) {
                                        if (comp.required) comp.required = false
                                        return null;
                                    }
                                }
                                return <Base
                                    key={index}
                                    schema={comp}
                                    onChange={e => {
                                        let data = Object.assign({}, this.state.data)
                                        data[lg.code][comp.field] = e
                                        if (this.props.onChange) {
                                            this.props.onChange(data);
                                        }
                                    }}
                                    value={this.state.data[lg.code][comp.field]}
                                />
                            })}
                        </TabPane>)}
                    </TabContent>
                    {this.props.children}
                </form>)
            }
        }
    }
}

export default FormSchema;