import React from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Label } from 'reactstrap';
import Modal from 'react-modal';
import request from '../services/request'
import helper from '../services/helper'
import imageCompression from 'browser-image-compression';
import i18next from 'i18next';
import { AppSwitch } from '@coreui/react'
import { Progress } from 'rsuite';
const { Line } = Progress;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '500px'
    }
};

export default class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: -1,
            src: '',
            cropResult: props && props.value || null,
            schema: props && props.schema || {},
            hover: false,
            isLoading: false,
            sizePromotion: '',
            reqCrop: false
        };
        this.cropImage = this.cropImage.bind(this);
    }
    interval = null;
    async componentDidMount() {
        this.setState({ urlImage: helper.configs.AMAZONE_S3.subConfigs.LINK_STATIC.value })
        let width = this.props.schema && Number(this.props.schema.imageWidth) || 250
        let height = this.props.schema && Number(this.props.schema.imageHeight) || 200
        // if (this.props.promotion) {
        //     let sizePromotion = helper.configs.IMAGE.subConfigs.IMAGE_PROMOTION.value
        //     this.setState({ sizePromotion })
        //     width = Number(sizePromotion.split(',')[0])
        //     height = Number(sizePromotion.split(',')[1])
        // }

        if (width > 400) {
            width = Math.round(width / 2)
            height = Math.round(height / 2)
            this.setState({ width, height, })
        } else this.setState({ width, height })
    }
    async componentWillReceiveProps(next) {
        await this.setState({
            cropResult: next.value || null,
            schema: next.schema || {}
        })

        let width = next.schema && Number(next.schema.imageWidth) || 250,
            height = next.schema && Number(next.schema.imageHeight) || 200

        // if (this.props.promotion) {
        //     width = Number(this.state.sizePromotion.split(',')[0])
        //     height = Number(this.state.sizePromotion.split(',')[1])
        // }

        if (width >= 400) {
            width = Math.round(width / 2)
            height = Math.round(height / 2)
            this.setState({ width, height })
        } else this.setState({ width, height })
    }
    onChange(e) {
        e.preventDefault();
        this.setState({ progress: -1, status: "" })

        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ src: reader.result, showModal: true });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    async cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            return;
        }

        await this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL(),
            showModal: false, isLoading: true, progress: 0
        });

        this.interval = setInterval(() => {
            if (this.state.progress < 95) {
                this.setState({ progress: this.state.progress + 1 });
            }
        }, 50)

        let width, height;
        if (this.state.sizePromotion) {
            width = Number(this.state.sizePromotion.split(',')[0])
            height = Number(this.state.sizePromotion.split(',')[1])
        } else {
            width = this.props.schema && Number(this.props.schema.imageWidth) || 250
            height = this.props.schema && Number(this.props.schema.imageHeight) || 200
        }

        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: width * (width / height),
            fileType: 'image/png'
        };

        const compressedPic = await imageCompression(this.DataURLtoFile(this.state.cropResult), options);
        this.setState({ cropResult: URL.createObjectURL(compressedPic) })
        this.uploadFile(compressedPic)
    }

    async uploadFile(imageFile) {
        try {
            const formData = new FormData();
            formData.append('image', imageFile);

            let rs = await request.upload('/image/upload', formData, 'POST');

            if (rs && rs.errorCode === 0) {
                clearInterval(this.interval)
                this.setState({ progress: 100, status: "success" });
                if (this.props.onChange) {
                    this.props.onChange(rs.data);
                }
            } else {
                this.setState({ progress: 95, status: "fail", cropResult: null });
            }
        } catch (e) {
            this.setState({ progress: 99, status: "fail", cropResult: null });
            console.warn('error upload image ' + e);
            helper.toast('error', i18next.t('internalServerError'))
        }
    }
    DataURLtoFile = (dataUrl, filename) => {
        let arr = dataUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    progressBar = () => {
        if (this.state.progress >= 0) {
            return <Line percent={this.state.progress} status={this.state.status} style={{ width: `${this.state.width}px` }} />
        }
    }

    render() {
        return (<>
            <div>
                <input type="file" accept="image/*" onChange={e => {
                    if (e) {
                        if (this.state.reqCrop) {
                            this.onChange(e)
                        } else {
                            this.setState({ progress: -1, status: 'pending', cropResult: e && e.target && URL.createObjectURL(e.target.files[0]) })
                            this.interval = setInterval(() => {
                                if (this.state.progress < 95) {
                                    this.setState({ progress: this.state.progress + 1 });
                                }
                            }, 50)
                            this.uploadFile(e.target.files[0])
                        }
                    }
                }} style={{ display: 'none' }} ref={fileInput => this.fileInput = fileInput} />
            </div>
            {this.state.showModal && (
                <Modal
                    isOpen={this.state.showModal}
                    style={customStyles}>
                    <h2>Crop Image</h2>
                    <div style={{ float: 'right', marginTop: '-9%' }}>
                        <Button className="btn btn-danger btn-sm" onClick={() => { this.setState({ showModal: false, progress: -1 }) }}>Close</Button>
                        <Button style={{ marginLeft: '5px' }} className="btn btn-success btn-sm" onClick={this.cropImage}>Crop</Button>
                    </div>
                    <hr />
                    <Cropper
                        style={{ height: '100%', width: '100%' }}
                        aspectRatio={this.state.width / this.state.height}
                        preview=".img-preview"
                        guides={false}
                        src={this.state.src}
                        ref={cropper => {
                            this.cropper = cropper;
                        }}
                        viewMode={1}
                        dragMode="move"
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        scalable={false}
                    />
                </Modal>
            )}
            {!this.state.cropResult && (
                <i className="fa fa-upload fa-3x" style={{ position: 'absolute', top: '40%', left: '12%' }} onClick={() => this.fileInput.click()}></i>
            )}
            {this.state.cropResult && (
                <i className="fa fa-upload fa-2x" style={{ position: 'absolute', color: 'green', top: '9px', left: '4%', cursor: 'pointer' }} onClick={() => this.fileInput.click()}></i>
            )}
            <img className='file-picker-thumbnail' style={{ marginTop: '10px' }}
                style={{
                    width: `${this.state.width}px`,
                    height: `${this.state.height}px`
                }}
                src={this.state.cropResult && this.state.cropResult.includes('ambulance/images/') ? this.state.urlImage + this.state.cropResult : this.state.cropResult}
                onClick={() => {
                    if (!this.state.cropResult) this.fileInput.click()
                }} />
            {this.progressBar()}
            <div className="mt-2">
                <AppSwitch
                    color={'success'}
                    onChange={() => {
                        this.setState(prevState => {
                            return { reqCrop: !prevState.reqCrop }
                        })
                    }}
                    checked={this.state.reqCrop} />
                <Label className="mt-1 ml-2" style={{ position: 'absolute' }}>Crop Image?</Label>
            </div>
        </>)
    }
}
