import React, { Component } from 'react';
// import { Input } from 'reactstrap';
import helper from '../services/helper';
import i18next from 'i18next';
import Select2 from 'react-select';

export default class SelectModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.ARRAY || [],
            pageId: props.schema.pageId,
            schema: props.schema,
            isCallData: props.isCallData || true,
            fieldsSave: []
        };
        if (this.state.data.length === 0) this.init(props.schema.pageId, props);
    }
    pageInfo = null;
    schema = null;
    componentWillReceiveProps(next) {
        if (!!!next.ARRAY.length) {
            if (this.props.value !== next.value) {
                this.fetchData(this.pageInfo, this.schema, next.value);
            }
        } else {
            let value = next.ARRAY.find(i => i.value === next.value)
            this.setState({ value })
        }
    }
    async init(pageId, props) {
        this.pageInfo = helper.getPage(pageId);
        this.schema = props.schema;
        this.fetchData(this.pageInfo, this.schema, props.value);
    }
    async fetchData(pageInfo, schema, value) {

        try {
            if (!pageInfo || !schema) return;

            if (schema.fieldsSave && schema.modelSelectField) {
                let fieldsSave = schema.fieldsSave.split(',')
                this.setState({ fieldsSave })

                let rs = await helper.callPageApi(pageInfo, schema.api, {
                    select: fieldsSave.length > 1 ? schema.fieldsSave : `${schema.fieldsSave},${schema.modelSelectField}`
                });

                if (rs && rs.data) {
                    if (fieldsSave.length > 1) {
                        rs.data.map(item => {
                            this.renameKey(item, fieldsSave[0], 'value')
                            this.renameKey(item, fieldsSave[1], 'label')
                        })
                    } else {
                        let modelSelectField = schema.modelSelectField.split(',');
                        rs.data.map(item => {
                            this.renameKey(item, schema.fieldsSave, 'value')
                            if (modelSelectField.length > 1) {
                                if (modelSelectField.includes(schema.fieldsSave)) {
                                    if (modelSelectField.length === 2) {
                                        item.label = item[modelSelectField[0]] + ' - ' + item.value
                                    } else if (modelSelectField.length === 3) {
                                        item.label = item[modelSelectField[0]] + ' - ' + item.value + ' - ' + item[modelSelectField[2]]
                                    }
                                }
                                else item.label = item[modelSelectField[0]] + ' - ' + item[modelSelectField[1]]
                            }
                            else this.renameKey(item, modelSelectField[0], 'label')
                        })
                    }
                }
                await this.setState({ data: rs.data ? rs.data : [] });
                if (value) {
                    value = rs.data.find(item => typeof value === 'object' ? value[fieldsSave[0]] === item.value : value === item.value)
                    this.setState({ value })
                }
            }
        } catch (err) {

            helper.toast('error', i18next.t('internalServerError'))
        }
    }
    async saveData(val) {
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }
    renameKey(obj, old_key, new_key) {
        if (obj[old_key])
            if (old_key !== new_key) {
                Object.defineProperty(obj, new_key, // modify old key 
                    // fetch description from object 
                    Object.getOwnPropertyDescriptor(obj, old_key));
                delete obj[old_key];                // delete old key 
            }
    }
    render() {
        if (this.schema && (!this.schema.modelSelectField || !this.schema.fieldsSave)) return <p>Missing field</p>

        let { data, value, fieldsSave } = this.state;
        let optionsSelect = []

        if (this.schema && this.schema.filterBy && !this.schema.filterBy.includes('=')) {
            if (this.props.valueFilter)
                optionsSelect = data.filter(item => {
                    let checker = item[this.schema.filterBy]
                    if (checker) {
                        if (typeof this.props.valueFilter === 'object') {
                            if (typeof checker === 'object') return this.props.valueFilter.sid === checker.sid ? item : ''
                            else return this.props.valueFilter.sid === checker ? item : ''
                        } else if (typeof this.props.valueFilter === 'string') {
                            if (typeof checker === 'object') return this.props.valueFilter === checker.sid ? item : ''
                            else return this.props.valueFilter === checker ? item : ''
                        }
                    }
                })

            else optionsSelect = []
        } else {
            optionsSelect = data
        }

        return (
            <Select2
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                options={optionsSelect}
                value={value}
                isDisabled={this.props.schema.disabled || false}
                isClearable={true}
                onChange={e => {
                    if (e)
                        if (this.props.ARRAY.length === 0) {
                            if (fieldsSave.length > 1) {
                                let number;
                                if (fieldsSave.indexOf('title') === 1) number = fieldsSave.findIndex(i => i === 'title')
                                if (this.props.onChange) {
                                    this.props.onChange({
                                        [fieldsSave[0]]: e.value,
                                        [number ? [fieldsSave[number]] : [fieldsSave[1]]]: e.label
                                    })
                                }
                            } else {
                                if (this.props.onChange) {
                                    this.props.onChange(e.value)
                                }
                            }
                        } else {
                            if (this.props.onChange) {
                                this.props.onChange(e.value)
                            }
                        }
                    else
                        if (this.props.onChange) {
                            this.props.onChange({})
                        }
                }}
            />
        )
    }
}   