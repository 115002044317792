import React from 'react';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/styles/rsuite-default.css";

export default class DateRange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            schema: props.schema || {}
        }
    }
    componentDidMount() {
        this.setState({ value: this.props.value })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value })
    }


    render() {
        let { value, schema } = this.state;

        return (
            <>
                <DateRangePicker size="md"
                    value={value}
                    format="DD/MM/YYYY"
                    onChange={async e => {
                        if (this.props.onChange) {
                            this.props.onChange(e)
                        }
                    }} />
            </>
        )
    }
}