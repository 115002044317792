import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker } from 'rsuite';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: props.schema,
            value: props.value ? moment(props.value).toDate() : new Date(),
            focus: false
        };
    }
    onChange(val) {
        this.setState({ value: val });
        if (this.props.onChange) {
            this.props.onChange(val);
            // if (val) {
            //     this.props.onChange(val.valueOf());
            // } else {            
            // }
        }
    }
    componentWillReceiveProps(next) {
        if (next.value !== moment(this.state.value).valueOf()) {
            this.setState({ value: next.value ? moment(next.value).toDate() : new Date() });
        }
    }
    render() {
        return (<div>
            {/* /**
            * @editedBy: TamDV @EditedAt:09/06/2020
            * @description: edit date ranger
            */}
            <DatePicker
                disabled={this.state.schema.disabled}
                onChange={e => {
                    this.onChange(e);
                }}
                block
                value={this.props.value}
            />
            {/* <DatePicker clear={true}
                showToday
                initialDate={this.props.value}
                onChange={e => {
                    this.onChange(e)this.onChange(e)
                }}
                disabled={this.state.schema.disabled}
                className="form-control full-width" /> */}
        </div>)
    }
}

export default index;